<template>
    <div>
        <b-tabs no-key-nav lazy pills class="schedule">
            <b-tab title="Mo">
                <mobile-interval v-for="interval in intervals.mon" :key="interval.id" :start="interval.startTime" :end="interval.endTime"
                                 :value="interval.value" :id="interval.id" :day="'mon'" :module-number="moduleNumber"></mobile-interval>
            </b-tab>
            <b-tab title="Tu">
                <mobile-interval v-for="interval in intervals.tue" :key="interval.id" :start="interval.startTime" :end="interval.endTime"
                                 :value="interval.value" :id="interval.id" :day="'tue'" :module-number="moduleNumber"></mobile-interval>
            </b-tab>
            <b-tab title="We">
                <mobile-interval v-for="interval in intervals.wed" :key="interval.id" :start="interval.startTime" :end="interval.endTime"
                                 :value="interval.value" :id="interval.id" :day="'wed'" :module-number="moduleNumber"></mobile-interval>
            </b-tab>
            <b-tab title="Th">
                <mobile-interval v-for="interval in intervals.thu" :key="interval.id" :start="interval.startTime" :end="interval.endTime"
                                 :value="interval.value" :id="interval.id" :day="'thu'" :module-number="moduleNumber"></mobile-interval>
            </b-tab>
            <b-tab title="Fr">
                <mobile-interval v-for="interval in intervals.fri" :key="interval.id" :start="interval.startTime" :end="interval.endTime"
                                 :value="interval.value" :id="interval.id" :day="'fri'" :module-number="moduleNumber"></mobile-interval>
            </b-tab>
            <b-tab title="Sa">
                <mobile-interval v-for="interval in intervals.sat" :key="interval.id" :start="interval.startTime" :end="interval.endTime"
                                 :value="interval.value" :id="interval.id" :day="'sat'" :module-number="moduleNumber"></mobile-interval>
            </b-tab>
            <b-tab title="Su">
                <mobile-interval v-for="interval in intervals.sun" :key="interval.id" :start="interval.startTime" :end="interval.endTime"
                                 :value="interval.value" :id="interval.id" :day="'sun'" :module-number="moduleNumber"></mobile-interval>
            </b-tab>
        </b-tabs>

        <b-modal id="info" ref="infoModal" title="Info" ok-only>
            You cannot add more than 6 intervals on the same day.
        </b-modal>

        <b-modal id="edit" ref="editModal" title="Edit Interval" ok-title="Finish" @ok="editInterval" centered>
            <form @submit.stop.prevent="editInterval">
                <b-row>
                    <b-col cols="9" sm="10" v-if="!isTypeOnOff" class="py-2">
                        <b-form-input ref="tempInput" :disabled="intervalSwitch === false" type="number" :min="-15" :max="80" :step="1"
                                      placeholder="Enter desired temperature" v-model="temperature" :formatter="setpointFormatter"></b-form-input>
                    </b-col>
                    <b-col cols="3" sm="2" class="py-2 d-flex align-items-center">
                        <c-switch class="mx-auto" color="primary" label outline="alt" v-model="intervalSwitch"></c-switch>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="10" class="pt-2">
                        Additional action
                    </b-col>
                    <b-col cols="10" class="py-2">
                        <b-form-select v-model="selectedAction" :options="actionOptions" class="mb-3">
                        </b-form-select>
                    </b-col>
                    <b-col cols="12">
                        <b-form-group label="Copy this day to:">
                            <b-form-checkbox-group id="copy-interval" v-model="formSelectedDays" :options="formSelectedOptions">
                            </b-form-checkbox-group>
                        </b-form-group>
                    </b-col>
                </b-row>

                <b-row>
                </b-row>
            </form>
        </b-modal>
    </div>
</template>

<script>
    import MobileInterval from './MobileInterval';
    import {fieldsToValue, parseTime, setSchedulesWatchers, valueToFields} from '@/utilities/interval.utility';
    import {mapGetters} from 'vuex';
    import {Switch as cSwitch} from '@coreui/vue'

    export default {
        name: "MobileSchedule",

        components: {
            MobileInterval,
            cSwitch
        },

        props: [
            "moduleNumber"
        ],

        data() {
            return {
                intervals: {
                    "mon": [
                        {
                            id: 1,
                            startTime: "00:00",
                            endTime: "24:00",
                            value: 999
                        }
                    ],
                    "tue": [
                        {
                            id: 1,
                            startTime: "00:00",
                            endTime: "24:00",
                            value: 999
                        }
                    ],
                    "wed": [
                        {
                            id: 1,
                            startTime: "00:00",
                            endTime: "24:00",
                            value: 999
                        }
                    ],
                    "thu": [
                        {
                            id: 1,
                            startTime: "00:00",
                            endTime: "24:00",
                            value: 999
                        }
                    ],
                    "fri": [
                        {
                            id: 1,
                            startTime: "00:00",
                            endTime: "24:00",
                            value: 999
                        }
                    ],
                    "sat": [
                        {
                            id: 1,
                            startTime: "00:00",
                            endTime: "24:00",
                            value: 999
                        }
                    ],
                    "sun": [
                        {
                            id: 1,
                            startTime: "00:00",
                            endTime: "24:00",
                            value: 999
                        }
                    ]
                },
                intervalSwitch: true,
                intervalId: null, //the id of the currently selected interval
                intervalDay: null, //abbreviation of the day of the week of the currently selected interval
                daysIndex: {
                    "mon": 0, "tue": 1, "wed": 2, "thu": 3, "fri": 4, "sat": 5, "sun": 6
                },
                intervalCount: 0,
                temperature: 15,
                previousTemp: 0,
                schedulesUnwatch: null,
                pendingInterval: [false, false, false, false, false, false, false], //flag if device is waiting for update
                sentIntervals: [],
                retryIntervals: [],
                numberOfRetries: [0, 0, 0, 0, 0, 0, 0],
                formSelectedDays: [],
                formSelectedOptions: [],
                selectedAction: null,
                actionOptions: [
                    {value: null, text: 'None'},
                    {value: 'add', text: 'Add new interval'},
                    {value: 'delete', text: 'Delete this interval'}
                ]
            }
        },

        computed: {
            ...mapGetters({
                getParameters: 'virtualDevice/parameters'
            }),

            parameters: function () {
                return this.getParameters(this.$route.params.id);
            },

            isTypeOnOff: function () {
                if (this.moduleNumber === 0) {
                    return true;
                } else if (this.moduleNumber === 1) {
                    return parseInt(this.parameters.p_78.value) === 3;
                } else if (this.moduleNumber === 2) {
                    return parseInt(this.parameters.p_81.value) === 3;
                } else if (this.moduleNumber === 3) {
                    return parseInt(this.parameters.p_84.value) === 3;
                }
            }
        },

        watch: {
            intervalSwitch: function (newVal, oldVal) {
                if (newVal === true) {
                    let intervalsOfDay = this.intervals[this.intervalDay];
                    let selectedItem = intervalsOfDay[this.intervalId - 1];

                    this.temperature = selectedItem.value;

                    if (this.temperature === '' || this.temperature === 999) {
                        this.temperature = 15;
                    }
                } else {
                    this.temperature = '';
                }
            }
        },

        mounted() {
            this.$root.$on('edit-interval', this.showEditModal);
            this.$root.$on('update:start-time', this.updateStartTime);
            this.$root.$on('update:end-time', this.updateEndTime);

            this.intervals = this.fetchSchedules();
            this.schedulesUnwatch = setSchedulesWatchers(this);
        },

        destroyed() {
            this.$root.$off('edit-interval');
            this.$root.$off('update:start-time');
            this.$root.$off('update:end-time');

            for (let index in this.schedulesUnwatch) {
                this.schedulesUnwatch[index]();
            }

            for (let index in this.retryIntervals) {
                if (this.retryIntervals[index]) clearInterval(this.retryIntervals[index]);
            }
        },

        methods: {
            /**
             * Splits the old interval in two new intervals
             */
            addInterval() {
                let intervalToSplit = this.intervals[this.intervalDay][this.intervalId - 1];

                if (this.intervalSwitch) {
                    this.temperature = this.temperature === "" ? 15 : this.temperature;
                    intervalToSplit.value = this.temperature;
                } else {
                    intervalToSplit.value = 999;
                }

                let mid = this.findMidTime(intervalToSplit);

                let interval1 = {
                    id: intervalToSplit.id,
                    day: this.intervalDay,
                    startTime: intervalToSplit.startTime,
                    endTime: mid,
                    value: intervalToSplit.value
                };

                let interval2 = {
                    id: intervalToSplit.id + 1,
                    day: this.intervalDay,
                    startTime: mid,
                    endTime: intervalToSplit.endTime,
                    value: intervalToSplit.value
                };

                let intervalsOfDay = this.intervals[this.intervalDay];
                intervalsOfDay.splice(this.intervalId - 1, 1); //remove element that is going to be split
                intervalsOfDay.forEach((interval) => {
                    if (interval.id > this.intervalId) { //because we want to create space for the new element and we want to ensure no duplicate ids
                        interval.id++;
                    }
                });
                intervalsOfDay.push(interval1);
                intervalsOfDay.push(interval2);
                intervalsOfDay.sort((a, b) => {
                    return a.id - b.id;
                });
            },

            /**
             * Shows modal for editing interval after correct values have been set for the modal component
             * @param intervalId - the id of the interval which was clicked
             * @param intervalDay - the weekday of the interval which was clicked
             * @param intervalValue - interval setpoint
             * @param moduleNumber - the number of the current module
             */
            showEditModal(intervalId, intervalDay, intervalValue, moduleNumber) {
                if (this.moduleNumber !== moduleNumber) return;

                this.intervalId = intervalId;
                this.intervalDay = intervalDay;
                this.intervalCount = this.intervals[intervalDay].length;
                this.intervalSwitch = this.intervals[intervalDay][intervalId - 1].value !== 999;
                if (this.intervalSwitch) {
                    this.temperature = intervalValue;
                } else {
                    this.temperature = '';
                }

                this.previousTemp = this.temperature;
                this.$set(this, 'selectedAction', null);
                this.$set(this, 'formSelectedDays', []);
                this.$set(this, 'formSelectedOptions', []);
                this.$set(this, 'formSelectedOptions', [
                    {value: 0, text: 'Mo'},
                    {value: 1, text: 'Tu'},
                    {value: 2, text: 'We'},
                    {value: 3, text: 'Th'},
                    {value: 4, text: 'Fr'},
                    {value: 5, text: 'Sa'},
                    {value: 6, text: 'Su'}
                ]);

                this.formSelectedOptions.splice(this.daysIndex[this.intervalDay], 1);

                this.$refs.editModal.show();
            },

            /**
             * Deletes the selected interval and decrements the ids of the intervals to the right of him so
             * that all interval ids are in ascending order. Also updates start end end times of intervals next to him
             */
            deleteInterval() {
                let intervalsOfDay = this.intervals[this.intervalDay];
                let intervalToDelete = intervalsOfDay[this.intervalId - 1];

                let intervalBefore = intervalsOfDay[this.intervalId - 2];
                if (intervalBefore !== undefined) { //interval before current one
                    intervalBefore.endTime = intervalToDelete.endTime;
                } else { //interval after current one
                    let intervalAfter = intervalsOfDay[this.intervalId];
                    intervalAfter.startTime = intervalToDelete.startTime;
                }
                intervalsOfDay.splice(this.intervalId - 1, 1);
                intervalsOfDay.forEach((interval) => {
                    if (interval.id > this.intervalId) {
                        interval.id--;
                    }
                });
            },

            /**
             * Store the values on the modal and hide it
             */
            editInterval() {
                if (this.intervalSwitch) {
                    this.temperature = this.temperature === "" ? 15 : this.temperature;
                    this.intervals[this.intervalDay][this.intervalId - 1].value = this.temperature;
                } else {
                    this.intervals[this.intervalDay][this.intervalId - 1].value = 999;
                }

                if (this.selectedAction === 'add') {
                    if (this.intervalCount < 6) {
                        this.addInterval();
                    } else {
                        this.$refs.infoModal.show();
                        return;
                    }
                } else if (this.selectedAction === 'delete') {
                    if (this.intervalCount > 1) {
                        this.deleteInterval();
                    } else return;
                }

                for (let key in this.formSelectedDays) {
                    this.copyInterval(this.formSelectedDays[key]);
                }

                if (this.temperature !== this.previousTemp || this.selectedAction) {
                    this.sendDayToDevice();
                }
                this.$refs.editModal.hide();
            }
            ,

            /**
             * Take schedule parameters from the parameters pool in virtual device store and format them properly
             * @returns {{thu: Array, tue: Array, wed: Array, sat: Array, fri: Array, mon: Array, sun: Array}}
             */
            fetchSchedules() {
                let items = {
                    "mon": [],
                    "tue": [],
                    "wed": [],
                    "thu": [],
                    "fri": [],
                    "sat": [],
                    "sun": []
                };

                if (this.moduleNumber === 0) {
                    let rawIntervals = this.parameters.p_41 !== undefined ? JSON.parse(this.parameters.p_41.value) : [];
                    items.mon = this.parseIntervals(rawIntervals);

                    rawIntervals = this.parameters.p_42 !== undefined ? JSON.parse(this.parameters.p_42.value) : [];
                    items.tue = this.parseIntervals(rawIntervals);

                    rawIntervals = this.parameters.p_43 !== undefined ? JSON.parse(this.parameters.p_43.value) : [];
                    items.wed = this.parseIntervals(rawIntervals);

                    rawIntervals = this.parameters.p_44 !== undefined ? JSON.parse(this.parameters.p_44.value) : [];
                    items.thu = this.parseIntervals(rawIntervals);

                    rawIntervals = this.parameters.p_45 !== undefined ? JSON.parse(this.parameters.p_45.value) : [];
                    items.fri = this.parseIntervals(rawIntervals);

                    rawIntervals = this.parameters.p_46 !== undefined ? JSON.parse(this.parameters.p_46.value) : [];
                    items.sat = this.parseIntervals(rawIntervals);

                    rawIntervals = this.parameters.p_47 !== undefined ? JSON.parse(this.parameters.p_47.value) : [];
                    items.sun = this.parseIntervals(rawIntervals);
                } else if (this.moduleNumber === 1) {
                    let rawIntervals = this.parameters.p_113 !== undefined ? JSON.parse(this.parameters.p_113.value) : [];
                    items.mon = this.parseIntervals(rawIntervals);

                    rawIntervals = this.parameters.p_114 !== undefined ? JSON.parse(this.parameters.p_114.value) : [];
                    items.tue = this.parseIntervals(rawIntervals);

                    rawIntervals = this.parameters.p_115 !== undefined ? JSON.parse(this.parameters.p_115.value) : [];
                    items.wed = this.parseIntervals(rawIntervals);

                    rawIntervals = this.parameters.p_116 !== undefined ? JSON.parse(this.parameters.p_116.value) : [];
                    items.thu = this.parseIntervals(rawIntervals);

                    rawIntervals = this.parameters.p_117 !== undefined ? JSON.parse(this.parameters.p_117.value) : [];
                    items.fri = this.parseIntervals(rawIntervals);

                    rawIntervals = this.parameters.p_118 !== undefined ? JSON.parse(this.parameters.p_118.value) : [];
                    items.sat = this.parseIntervals(rawIntervals);

                    rawIntervals = this.parameters.p_119 !== undefined ? JSON.parse(this.parameters.p_119.value) : [];
                    items.sun = this.parseIntervals(rawIntervals);
                } else if (this.moduleNumber === 2) {
                    let rawIntervals = this.parameters.p_120 !== undefined ? JSON.parse(this.parameters.p_120.value) : [];
                    items.mon = this.parseIntervals(rawIntervals);

                    rawIntervals = this.parameters.p_121 !== undefined ? JSON.parse(this.parameters.p_121.value) : [];
                    items.tue = this.parseIntervals(rawIntervals);

                    rawIntervals = this.parameters.p_122 !== undefined ? JSON.parse(this.parameters.p_122.value) : [];
                    items.wed = this.parseIntervals(rawIntervals);

                    rawIntervals = this.parameters.p_123 !== undefined ? JSON.parse(this.parameters.p_123.value) : [];
                    items.thu = this.parseIntervals(rawIntervals);

                    rawIntervals = this.parameters.p_124 !== undefined ? JSON.parse(this.parameters.p_124.value) : [];
                    items.fri = this.parseIntervals(rawIntervals);

                    rawIntervals = this.parameters.p_125 !== undefined ? JSON.parse(this.parameters.p_125.value) : [];
                    items.sat = this.parseIntervals(rawIntervals);

                    rawIntervals = this.parameters.p_126 !== undefined ? JSON.parse(this.parameters.p_126.value) : [];
                    items.sun = this.parseIntervals(rawIntervals);
                } else if (this.moduleNumber === 3) {
                    let rawIntervals = this.parameters.p_127 !== undefined ? JSON.parse(this.parameters.p_127.value) : [];
                    items.mon = this.parseIntervals(rawIntervals);

                    rawIntervals = this.parameters.p_128 !== undefined ? JSON.parse(this.parameters.p_128.value) : [];
                    items.tue = this.parseIntervals(rawIntervals);

                    rawIntervals = this.parameters.p_129 !== undefined ? JSON.parse(this.parameters.p_129.value) : [];
                    items.wed = this.parseIntervals(rawIntervals);

                    rawIntervals = this.parameters.p_130 !== undefined ? JSON.parse(this.parameters.p_130.value) : [];
                    items.thu = this.parseIntervals(rawIntervals);

                    rawIntervals = this.parameters.p_131 !== undefined ? JSON.parse(this.parameters.p_131.value) : [];
                    items.fri = this.parseIntervals(rawIntervals);

                    rawIntervals = this.parameters.p_132 !== undefined ? JSON.parse(this.parameters.p_132.value) : [];
                    items.sat = this.parseIntervals(rawIntervals);

                    rawIntervals = this.parameters.p_133 !== undefined ? JSON.parse(this.parameters.p_133.value) : [];
                    items.sun = this.parseIntervals(rawIntervals);
                }

                return items;
            }
            ,

            /**
             * Converts intervals from a JSON string into an array
             * @param rawIntervals - JSON string representing the intervals for a weekday
             * @returns {Array} - intervals formatted as an array
             */
            parseIntervals(rawIntervals) {
                let items = [];

                let parsedIntervals = [];
                for (let interval in rawIntervals) {
                    parsedIntervals[interval] = valueToFields(rawIntervals[interval]);
                }

                if (parsedIntervals.length === 0) {
                    items.push({
                        id: 1,
                        startTime: "00:00",
                        endTime: "24:00",
                        value: 999
                    })
                } else {
                    let temp, startHour, startMin, endHour, endMin, startTime, endTime;
                    for (let key = 1; key < parsedIntervals.length; key++) {
                        temp = parsedIntervals[key][0];
                        startHour = parsedIntervals[key][1];
                        startMin = parsedIntervals[key][2];
                        startTime = parseTime(startHour, startMin);
                        if (key === parsedIntervals.length - 1) {
                            endTime = "24:00";
                        } else {
                            endHour = parsedIntervals[key + 1][1];
                            endMin = parsedIntervals[key + 1][2];
                            endTime = parseTime(endHour, endMin);
                        }

                        items.push({
                            id: key,
                            startTime: startTime,
                            endTime: endTime,
                            value: temp
                        })
                    }

                    return items;
                }
            }
            ,

            /**
             * If temperature is 999 (Off) don't display anything in edit modal input
             * @param value
             * @param event
             */
            setpointFormatter(value, event) {
                if ((value.match(/0/g) || []).length >= 2) { //prevent user from entering multiple zeros in a row
                    this.$refs['tempInput'].$emit('update', '');
                    this.temperature = 0;
                    return 0;
                } else if (parseInt(value) < -15) {
                    this.$refs['tempInput'].$emit('update', '');
                    this.temperature = -15;
                    return "-15";
                } else if (parseInt(value) > 80) {
                    this.$refs['tempInput'].$emit('update', '');
                    this.temperature = 80;
                    return "80";
                } else {
                    this.$refs['tempInput'].$emit('update', '');
                    this.temperature = value;
                    return value.toString();
                }
            }
            ,

            /**
             * Copy the current selected day into another day
             * @param destinationDayId - day which will be overwritten
             */
            copyInterval(destinationDayId) {
                let items = this.intervals[this.intervalDay];
                let itemsToAdd = items.slice(); //copy array

                let weekday;
                for (let key in this.daysIndex) {
                    if (this.daysIndex[key] === destinationDayId) {
                        weekday = key;
                    }
                }
                this.$set(this.intervals, weekday, itemsToAdd);

                this.sendDayToDevice(weekday);
            }
            ,

            /**
             * Format intervals of the current selected day into a JSON object and send the to the physical device
             */
            sendDayToDevice(day = null) {
                if (day === null) day = this.intervalDay;


                let items = this.intervals[day];

                //sort items by start time in ascending order
                items.sort(function (a, b) {
                    return a.startTime - b.startTime;
                });

                let parameter = {}, interval = {};
                let item, setpoint, value;
                for (let i = 0; i < items.length; i++) {
                    item = items[i];
                    setpoint = item.value;

                    if (setpoint === "Off") setpoint = 999;
                    else setpoint = parseInt(setpoint);

                    value = fieldsToValue(setpoint, item.startTime);
                    interval[i + 1] = value;
                }

                parameter.id = this.$route.params.id;
                let key = "p_";
                if (this.moduleNumber === 0) {
                    key += (41 + this.daysIndex[day]);
                } else if (this.moduleNumber === 1) {
                    key += (113 + this.daysIndex[day]);
                } else if (this.moduleNumber === 2) {
                    key += (120 + this.daysIndex[day]);
                } else if (this.moduleNumber === 3) {
                    key += (127 + this.daysIndex[day]);
                }

                parameter[key] = interval;

                this.$axios.post('/update-parameter', {
                    data: parameter
                });

                if (this.retryIntervals[this.daysIndex[day]] !== undefined) {
                    clearInterval(this.retryIntervals[this.daysIndex[day]]);
                }

                this.retryIntervals[this.daysIndex[day]] = setInterval(() => {
                    this.numberOfRetries[this.daysIndex[day]] += 1;

                    if (this.numberOfRetries[this.daysIndex[day]] <= 5) {
                        this.$axios.post('/update-parameter', {
                            data: parameter
                        });
                    } else {
                        this.$set(this.pendingInterval, this.daysIndex[day], false);
                        this.numberOfRetries[this.daysIndex[day]] = 0;
                        clearInterval(this.pendingInterval[this.daysIndex[day]]);
                        clearInterval(this.retryIntervals[this.daysIndex[day]]);
                        this.$root.$emit('show-schedule-spinner', false);
                        this.$root.$emit('show-warning-label', true);
                        this.updateDay(JSON.parse(this.parameters[key].value), this.daysIndex[day] + 1);
                    }
                }, 5000, parameter);

                this.$set(this.pendingInterval, "" + (this.daysIndex[day]), true);
                this.sentIntervals[this.daysIndex[day]] = JSON.stringify(interval);
                this.$root.$emit('show-warning-label', false);
                this.$root.$emit('show-schedule-spinner', true);
            }
            ,

            /**
             * Watcher callback when a schedule parameter has changed
             * @param newParam - New parameter object after watch
             * @param oldParam - Old parameter object before watch
             */
            scheduleWatcher(newParam, oldParam) {
                if (!newParam) return; //because schedule params throw errors when schedule operation is set to disabled and the watcher is called afterwards

                let paramIndexes = {
                    "41": 0, "42": 1, "43": 2, "44": 3, "45": 4, "46": 5, "47": 6,
                    "113": 0, "114": 1, "115": 2, "116": 3, "117": 4, "118": 5, "119": 6,
                    "120": 0, "121": 1, "122": 2, "123": 3, "124": 4, "125": 5, "126": 6,
                    "127": 0, "128": 1, "129": 2, "130": 3, "131": 4, "132": 5, "133": 6
                };
                let index = paramIndexes[newParam.id];

                if (newParam.value !== oldParam.value && !this.pendingInterval[index]) {
                    this.updateDay(JSON.parse(newParam.value), index + 1);
                } else if (newParam.value === this.sentIntervals[index] && this.pendingInterval[index]) {
                    this.$set(this.pendingInterval, index.toString(), false);
                    clearInterval(this.retryIntervals[index]);
                    this.$root.$emit('show-schedule-spinner', false);
                }
            }
            ,

            /**
             * Refresh intervals of this day with data received from physical device
             * @param rawIntervals - JSON string which represents the interval
             * @param dayIndex - the weekday index of the interval
             */
            updateDay(rawIntervals, dayIndex) {
                let weekDays = ["mon", "tue", "wed", "thu", "fri", "sat", "sun"];
                let intervals = this.parseIntervals(rawIntervals);
                this.$set(this.intervals, weekDays[dayIndex - 1], intervals);
            }
            ,

            /**
             * Update start time in current interval and set end time in the interval before this equal to start time as they must always match
             * @param startTime
             * @param id - id of the interval being edited
             * @param day - weekday of the interval being edited
             */
            updateStartTime(startTime, id, day, moduleNumber) {
                if (this.moduleNumber !== moduleNumber) return;

                let intervalBefore = this.intervals[day][id - 2];
                let currentInterval = this.intervals[day][id - 1];

                if (startTime >= currentInterval.endTime || (intervalBefore !== undefined && startTime <= intervalBefore.startTime)) {
                    //delete interval
                    this.intervalId = currentInterval.id;
                    this.intervalDay = currentInterval.day;
                    this.deleteInterval();
                } else {
                    currentInterval.startTime = startTime;
                    if (intervalBefore !== undefined) {
                        intervalBefore.endTime = startTime;
                    }
                }

                this.intervalId = id;
                this.intervalDay = day;
                this.sendDayToDevice();
            }
            ,

            /**
             * Update end time in current interval and set start time in the interval after this equal to end time as they must always match
             * @param endTime
             * @param id - id of the interval being edited
             * @param day - weekday of the interval being edited
             */
            updateEndTime(endTime, id, day, moduleNumber) {
                if (this.moduleNumber !== moduleNumber) return;

                let intervalAfter = this.intervals[day][id];
                let currentInterval = this.intervals[day][id - 1];

                if (endTime <= currentInterval.startTime || (intervalAfter !== undefined && endTime >= intervalAfter.endTime)) {
                    //delete interval
                    this.intervalId = currentInterval.id;
                    this.intervalDay = currentInterval.day;
                    this.deleteInterval();
                } else {
                    currentInterval.endTime = endTime;
                    if (intervalAfter !== undefined) {
                        intervalAfter.startTime = endTime;
                    }
                }

                this.intervalId = id;
                this.intervalDay = day;
                this.sendDayToDevice();
            }
            ,

            /**
             * We need to find mid time because an interval is going to be split in two other intervals where the end time of the first
             * and the start time of the second are going to be equal to mid time
             * @param intervalToSplit - object representation of the interval that is going to be split
             * @returns {string} - mid time formatted ass HH:mm
             */
            findMidTime(intervalToSplit) {
                let startTime = intervalToSplit.startTime;
                let endTime = intervalToSplit.endTime;
                startTime = startTime.split(":");
                endTime = endTime.split(":");
                let startHour = parseInt(startTime[0]);
                let endHour = parseInt(endTime[0]);
                let startMinute = parseInt(startTime[1]);
                let endMinute = parseInt(endTime[1]);
                let totalInMinutes = ((endHour * 60 + endMinute) + (startHour * 60 + startMinute)) / 2;
                let hours = Math.floor(totalInMinutes / 60);
                let minutes = Math.round((totalInMinutes % 60) / 15) * 15;

                return parseTime(hours, minutes);
            }
        }

    }
</script>

<style>
    .schedule .tab-content {
        border: none;
    }

    .schedule .tab-content .tab-pane {
        padding: 1rem 0 0 0rem;
    }
</style>































