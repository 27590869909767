<template>
    <b-tabs no-key-nav pills card id="device-settings" content-class="edit-section-tab">
        <b-tab key="1" title="General" title-link-class="bTabLinkClass">
            <template slot="title"><i class="icon-settings tab-icon px-2"></i></template>
            <GeneralSettings></GeneralSettings>
        </b-tab>

        <b-tab key="2" lazy v-if="shouldShow('Settings')" title-link-class="bTabLinkClass">
            <template slot="title"><i class="icon-wrench tab-icon px-2"></i></template>
            <h4 style="padding-bottom: 1.2em;"><i><em>Settings</em></i></h4>

            <div v-if="shouldShow('Root Settings')" class="pb-3">
                <Parameter v-for="(value, key) in parameters" :key="key" v-if='categoriesLookup["Settings"]["Root Settings"].indexOf(key) > -1'
                           :param-key="key" :param-value="value"></Parameter>
            </div>

            <div v-if="shouldShow('Heat Transfer')">
                <strong>Heat Transfer</strong>
                <hr>
                <br>
                <Parameter v-for="(value, key) in parameters" :key="key" v-if='categoriesLookup["Settings"]["Heat Transfer"].indexOf(key) > -1'
                           :param-key="key" :param-value="value"></Parameter>
            </div>

            <div v-if="shouldShow('Heat Pump')">
                <strong>Heat Pump</strong>
                <hr>
                <br>
                <Parameter v-if='categoriesLookup["Settings"]["Heat Pump"].indexOf("p_68") > -1' :key="'p_68'" :param-key="'p_68'"
                           :param-value="parameters['p_68']"></Parameter>

                <Parameter v-if='categoriesLookup["Settings"]["Heat Pump"].indexOf("p_69") > -1' :key="'p_69'" :param-key="'p_69'"
                           :param-value="parameters['p_69']"></Parameter>

                <div v-if="parseInt(parameters['p_68'].value) === 2" class="py-3">
                    <div class="py-3">
                        <b-row>
                            <b-col>
                                <strong class="float-left mr-2">Schedule</strong>
                                <spinner v-if="showScheduleSpinner" size="20" class="float-left"></spinner>
                            </b-col>
                        </b-row>
                        <hr>
                        <mobile-schedule v-if="shouldRenderMobileSchedule" :module-number="0"></mobile-schedule>
                        <desktop-schedule v-else :module-number="0"></desktop-schedule>
                    </div>
                </div>
            </div>
        </b-tab>

        <b-tab key="3" lazy v-if="shouldShow('Modules')" title-link-class="bTabLinkClass">
            <template slot="title"><i class="icon-layers tab-icon px-2"></i></template>
            <h4 style="padding-bottom: 1.2em;"><i><em>Modules</em></i></h4>

            <Module :module-number="1" :module-name="'Module 1'" :modules-lookup="this.categoriesLookup['Modules']"></Module>

            <Module :module-number="2" :module-name="'Module 2'" :modules-lookup="this.categoriesLookup['Modules']"></Module>

            <Module :module-number="3" :module-name="'Module 3'" :modules-lookup="this.categoriesLookup['Modules']"></Module>

            <ModuleMonitor></ModuleMonitor>
        </b-tab>

        <b-tab key="4" lazy v-if="shouldShow('Protections')" title-link-class="bTabLinkClass">
            <template slot="title"><i class="icon-shield tab-icon px-2"></i></template>
            <h4 style="padding-bottom: 1.2em;"><i><em>Protections</em></i></h4>

            <div v-if="shouldShow('System')">
                <strong>System</strong>
                <hr>
                <br>
                <Parameter v-for="(value, key) in parameters" :key="key" v-if='categoriesLookup["Protections"]["System"].indexOf(key) > -1
                            && shouldRenderParameter(key)' :param-key="key" :param-value="value"></Parameter>
            </div>

            <div v-if="shouldShow('Collector')">
                <br><strong>Collector</strong>
                <hr>
                <br>
                <Parameter v-for="(value, key) in parameters" :key="key" v-if='categoriesLookup["Protections"]["Collector"].indexOf(key) > -1
                            && shouldRenderParameter(key)' :param-key="key" :param-value="value"></Parameter>
            </div>

            <div v-if="shouldShow('Recooling')">
                <br><strong>Recooling</strong>
                <hr>
                <br>
                <Parameter v-for="(value, key) in parameters" :key="key" v-if='categoriesLookup["Protections"]["Recooling"].indexOf(key) > -1
                            && shouldRenderParameter(key)' :param-key="key" :param-value="value"></Parameter>
            </div>

            <div v-if="shouldShow('Frost')">
                <br><strong>Frost</strong>
                <hr>
                <br>
                <Parameter v-for="(value, key) in parameters" :key="key" v-if='categoriesLookup["Protections"]["Frost"].indexOf(key) > -1
                           && shouldRenderParameter(key)' :param-key="key" :param-value="value"></Parameter>
            </div>

            <div v-if="shouldShow('Seizing')">
                <br><strong>Seizing</strong>
                <hr>
                <br>
                <Parameter v-for="(value, key) in parameters" :key="key" v-if='categoriesLookup["Protections"]["Seizing"].indexOf(key) > -1'
                           :param-key="key" :param-value="value"></Parameter>
            </div>

            <div v-if="shouldShow('Storage Alarm')">
                <br><strong>Storage Alarm</strong>
                <hr>
                <br>
                <Parameter v-for="(value, key) in parameters" :key="key" v-if='categoriesLookup["Protections"]["Storage Alarm"].indexOf(key) > -1
                            && shouldRenderParameter(key)' :param-key="key" :param-value="value"></Parameter>
            </div>
        </b-tab>

        <b-tab key="5" lazy v-if="shouldShow('Manual Operations')" title-link-class="bTabLinkClass">
            <template slot="title"><i class="icon-game-controller tab-icon px-2"></i></template>
            <h4 style="padding-bottom: 1.2em;"><i><em>Manual Operations</em></i></h4>

            <Parameter v-for="(value, key) in parameters" :key="key" :param-key="key" :param-value="value"
                       v-if='categoriesLookup["Manual Operations"].indexOf(key) > -1 && showManualOperationKey({key: key, deviceId: $route.params.id})'>
            </Parameter>
        </b-tab>

        <b-tab key="6" lazy v-if="shouldShow('Root Special Functions')" title-link-class="bTabLinkClass">
            <template slot="title"><i class="icon-magic-wand tab-icon px-2"></i></template>
            <h4 style="padding-bottom: 1.2em;"><i><em>Setup</em></i></h4>

            <div v-if="shouldShow('Root Special Functions')">
                <Parameter v-for="(value, key) in parameters" :key="key"
                           v-if='categoriesLookup["Special Functions"]["Root Special Functions"].indexOf(key) > -1' :param-key="key"
                           :param-value="value"></Parameter>
            </div>

            <div v-if="shouldShow('Datetime')">
                <br><strong>Datetime</strong>
                <hr>
                <br>
                <Parameter v-for="(value, key) in parameters" :key="key"
                           v-if='categoriesLookup["Special Functions"]["Datetime"].indexOf(key) > -1' :param-key="key"
                           :param-value="value"></Parameter>
            </div>

            <div v-if="userPrivilege === 2 || userPrivilege === 3" class="mt-5">
                <device-templates></device-templates>
            </div>
        </b-tab>
    </b-tabs>
</template>

<script>
    import Header from "@/components/layout/Header";
    import Module from "@/components/virtual-device/edit/modules/ModuleV2";
    import ModuleMonitor from "@/components/virtual-device/edit/modules/ModuleMonitor";
    import Parameter from "@/components/virtual-device/edit/parameter/ParameterV2";
    import DeviceTemplates from "@/components/virtual-device/edit/device-templates/DeviceTemplates";
    import Spinner from 'vue-simple-spinner';
    import GeneralSettings from "@/components/virtual-device/edit/general-settings/GeneralSettings";
    import {mapGetters} from 'vuex';

    const MobileSchedule = () => import('@/components/virtual-device/edit/schedules/MobileSchedule');
    const DesktopSchedule = () => import('@/components/virtual-device/edit/schedules/DesktopSchedule');

    export default {
        name: "View1",

        components: {
            DeviceTemplates,
            Header,
            Module,
            ModuleMonitor,
            GeneralSettings,
            Parameter,
            Spinner,
            DesktopSchedule,
            MobileSchedule
        },

        data() {
            return {
                categoriesLookup: {
                    "Settings": {
                        "Root Settings": ["p_6", "p_7", "p_8", "p_9", "p_27", "p_28", "p_29", "p_30", "p_31", "p_32", "p_33", "p_34"],
                        "Heat Transfer": ["p_64", "p_65", "p_66", "p_67"],
                        "Heat Pump": ["p_41", "p_42", "p_43", "p_44", "p_45", "p_46", "p_47", "p_68", "p_69"]
                    },
                    "Protections": {
                        "System": ["p_10", "p_11", "p_12"],
                        "Collector": ["p_13", "p_14", "p_15", "p_16"],
                        "Recooling": ["p_17", "p_18"],
                        "Frost": ["p_19", "p_20", "p_21"],
                        "Seizing": ["p_22", "p_23", "p_24"],
                        "Storage Alarm": ["p_25", "p_26"]
                    },
                    "Special Functions": {
                        "Root Special Functions": ["p_1"],
                        "Datetime": ["p_70", "p_71"]
                    },
                    "Manual Operations": ["p_72", "p_73", "p_74", "p_75"],
                    "Modules": {
                        "Module 1": ["p_78", "p_35", "p_36", "p_37", "p_38", "p_39", "p_40", "p_63", "p_79", "p_80", "p_101", "p_102", "p_103", "p_104", "p_177"],
                        "Module 2": ["p_81", "p_82", "p_83", "p_87", "p_88", "p_89", "p_90", "p_91", "p_92", "p_93", "p_105", "p_106", "p_107", "p_108", "p_178"],
                        "Module 3": ["p_84", "p_85", "p_86", "p_94", "p_95", "p_96", "p_97", "p_98", "p_99", "p_100", "p_109", "p_110", "p_111", "p_112", "p_179"]
                    }
                },
                showScheduleSpinner: false,
                windowWidth: 0
            }
        },

        watch: {
            windowWidth: function (newWidth, oldWidth) {
                if (oldWidth <= 976 && newWidth > 976) { //execute only when passing the 976 breakpoint for the first time
                    this.$nextTick(() => {
                        this.$root.$emit("mount-timeline", 0);
                    })
                }
            }
        },

        computed: {
            ...mapGetters({
                getUserPrivilege: 'virtualDevice/userPrivilege',
                getParameters: 'virtualDevice/parameters',
                showManualOperationKey: 'virtualDevice/showManualOperationKey'
            }),

            userPrivilege() {
                return this.getUserPrivilege(this.$route.params.id);
            },

            parameters: function () {
                return this.getParameters(this.$route.params.id);
            },

            shouldRenderMobileSchedule: function () {

                return this.windowWidth <= 976;
            }
        },

        created() {
            this.$root.$on('show-schedule-spinner', (value) => {
                this.showScheduleSpinner = value;
            });

            window.addEventListener('resize', () => {
                this.windowWidth = window.innerWidth;
            });

            this.windowWidth = window.innerWidth;
        },

        methods: {
            /**
             * Checks if a category should render or not
             * @param categoryName
             * @returns {boolean}
             */
            shouldShow(categoryName) {
                let parameters = Object.keys(this.parameters);

                if (categoryName === "Root Settings" && this.categoriesLookup['Settings']['Root Settings'] !== undefined) {
                    return parameters.some(r => this.categoriesLookup['Settings']['Root Settings'].includes(r));
                } else if (categoryName === "Heat " && this.categoriesLookup['Settings']['Thermostat'] !== undefined) {
                    return parameters.some(r => this.categoriesLookup['Settings']['Thermostat'].includes(r));
                } else if (categoryName === "Recycle" && this.categoriesLookup['Settings']['Recycle'] !== undefined) {
                    return parameters.some(r => this.categoriesLookup['Settings']['Recycle'].includes(r));
                } else if (categoryName === "Heat Transfer" && this.categoriesLookup['Settings']['Heat Transfer'] !== undefined) {
                    return parameters.some(r => this.categoriesLookup['Settings']['Heat Transfer'].includes(r));
                } else if (categoryName === "Heat Pump" && this.categoriesLookup['Settings']['Heat Pump'] !== undefined) {
                    return parameters.some(r => this.categoriesLookup['Settings']['Heat Pump'].includes(r));
                } else if (categoryName === "System" && this.categoriesLookup['Protections']['System'] !== undefined) {
                    return parameters.some(r => this.categoriesLookup['Protections']['System'].includes(r));
                } else if (categoryName === "Collector" && this.categoriesLookup['Protections']['Collector'] !== undefined) {
                    return parameters.some(r => this.categoriesLookup['Protections']['Collector'].includes(r));
                } else if (categoryName === "Recooling" && this.categoriesLookup['Protections']['Recooling'] !== undefined) {
                    return parameters.some(r => this.categoriesLookup['Protections']['Recooling'].includes(r));
                } else if (categoryName === "Frost" && this.categoriesLookup['Protections']['Frost'] !== undefined) {
                    return parameters.some(r => this.categoriesLookup['Protections']['Frost'].includes(r));
                } else if (categoryName === "Seizing" && this.categoriesLookup['Protections']['Seizing'] !== undefined) {
                    return parameters.some(r => this.categoriesLookup['Protections']['Seizing'].includes(r));
                } else if (categoryName === "Storage Alarm" && this.categoriesLookup['Protections']['Storage Alarm'] !== undefined) {
                    return parameters.some(r => this.categoriesLookup['Protections']['Storage Alarm'].includes(r));
                } else if (categoryName === "Datetime" && this.categoriesLookup['Special Functions']['Datetime'] !== undefined) {
                    return parameters.some(r => this.categoriesLookup['Special Functions']['Datetime'].includes(r));
                } else if (categoryName === "Settings" && this.categoriesLookup['Settings'] !== undefined) {
                    return parameters.some(r => this.categoriesLookup['Settings']['Root Settings'].includes(r))
                        || parameters.some(r => this.categoriesLookup['Settings']['Heat Pump'].includes(r))
                        || parameters.some(r => this.categoriesLookup['Settings']['Heat Transfer'].includes(r));
                } else if (categoryName === "Modules" && this.categoriesLookup['Modules'] !== undefined) {
                    return parameters.some(r => this.categoriesLookup['Modules']['Module 1'].includes(r))
                        || parameters.some(r => this.categoriesLookup['Modules']['Module 2'].includes(r))
                        || parameters.some(r => this.categoriesLookup['Modules']['Module 3'].includes(r));
                } else if (categoryName === "Protections" && this.categoriesLookup['Protections'] !== undefined) {
                    return parameters.some(r => this.categoriesLookup['Protections']['System'].includes(r));
                } else if (categoryName === "Manual Operations" && this.categoriesLookup['Manual Operations'] !== undefined) {
                    return parameters.some(r => this.categoriesLookup['Manual Operations'].includes(r));
                } else if (categoryName === "Root Special Functions" && this.categoriesLookup['Special Functions']['Root Special Functions'] !== undefined) {
                    return parameters.some(r => this.categoriesLookup['Special Functions']['Root Special Functions'].includes(r));
                } else return false;
            },

            /**
             * Checks if a parameter should render or not
             * @param key
             * @returns {boolean}
             */
            shouldRenderParameter(key) {

                if (key === 'p_11' || key === 'p_12') return parseInt(this.parameters.p_10.value) !== 0; //Don't display parameter if protection isn't active
                else if (key === 'p_14' || key === 'p_15' || key === 'p_16') return parseInt(this.parameters.p_13.value) !== 0;
                else if (key === 'p_18') return parseInt(this.parameters.p_17.value) !== 0;
                else if (key === 'p_20' || key === 'p_21') return parseInt(this.parameters.p_19.value) !== 0;
                else if (key === 'p_26') return parseInt(this.parameters.p_25.value) !== 0;
                else return true;

            }
        }
    }
</script>

<style scoped>

</style>
