<template>
    <div v-if="getAvailableSensors[0] === 1 || getAvailableSensors[1] === 1 || getAvailableSensors[2] === 1 || getAvailableSensors[3] === 1"
         class="py-2">

        <strong>Monitor</strong>
        <b-btn v-b-toggle="'moduleMonitor'" variant="primary" class="collapse-btn-style" @pointerdown="collapseOpen = !collapseOpen"
               style="margin: 0px 10px 0px 20px;">{{ collapseIcon }}
        </b-btn>
        <b-collapse id="moduleMonitor" class="mt-2">
            <b-card style="border: none;">
                <hr style="margin-top: 0.4rem;">

                <parameter param-key="p_181" :param-value="parameters['p_181']"></parameter>
                <div v-if="parameters['p_181'].value != 0">
                    <parameter v-if="getAvailableSensors[0] == 1" param-key="p_182" :param-value="parameters['p_182']"></parameter>
                    <parameter v-if="getAvailableSensors[1] == 1" param-key="p_183" :param-value="parameters['p_183']"></parameter>
                    <parameter v-if="getAvailableSensors[2] == 1" param-key="p_184" :param-value="parameters['p_184']"></parameter>
                    <parameter v-if="getAvailableSensors[3] == 1" param-key="p_185" :param-value="parameters['p_185']"></parameter>
                </div>

            </b-card>
        </b-collapse>
    </div>
</template>

<script>
    import {mapGetters} from 'vuex';
    import Parameter from "@/components/virtual-device/edit/parameter/v3/Parameter";

    export default {
        name: "ModuleMonitor",

        components: {
            Parameter
        },

        data() {
            return {
                collapseOpen: false
            }
        },

        computed: {
            ...mapGetters({
                getParameters: 'virtualDevice/parameters',
                getDeviceVariant: 'virtualDevice/deviceVariant'
            }),

            parameters: function () {
                return this.getParameters(this.$route.params.id);
            },

            collapseIcon: function () {
                return this.collapseOpen === false ? '+' : '-';
            },

            getAvailableSensors: function () {
                let availableSensors = [0, 0, 0, 0];
                let parameters = this.getParameters(this.$route.params.id);
                let deviceVariant = this.getDeviceVariant(this.$route.params.id);


                switch (deviceVariant) {
                    case 1:
                        availableSensors = [0, 0, 1, 1];
                        if (parameters.p_81.value == 1) {
                            availableSensors[parameters.p_83.value] = 0; //when module is thermostat or recycle this sensor is being used
                        } else if (parameters.p_81.value == 2) {
                            availableSensors[parameters.p_83.value] = 0;
                            availableSensors[parameters.p_178.value] = 0; //when module is recycle this additional sensor is being used
                        }

                        if (parameters.p_84.value == 1) {
                            availableSensors[parameters.p_86.value] = 0;
                        } else if (parameters.p_84.value == 2) {
                            availableSensors[parameters.p_86.value] = 0;
                            availableSensors[parameters.p_179.value] = 0;
                        }
                        break;
                    case 2:
                        availableSensors = [0, 0, 0, 1];

                        if (parameters.p_84.value == 1) {
                            availableSensors[parameters.p_86.value] = 0;
                        } else if (parameters.p_84.value == 2) {
                            availableSensors[parameters.p_86.value] = 0;
                            availableSensors[parameters.p_179.value] = 0;
                        }
                        break;
                    case 3:
                        availableSensors = [0, 0, 0, 1];

                        if (parameters.p_84.value == 1) {
                            availableSensors[parameters.p_86.value] = 0;
                        } else if (parameters.p_84.value == 2) {
                            availableSensors[parameters.p_86.value] = 0;
                            availableSensors[parameters.p_179.value] = 0;
                        }
                        break;
                    case 4:
                        availableSensors = [0, 0, 0, 1];

                        if (parameters.p_84.value == 1) {
                            availableSensors[parameters.p_86.value] = 0;
                        } else if (parameters.p_84.value == 2) {
                            availableSensors[parameters.p_86.value] = 0;
                            availableSensors[parameters.p_179.value] = 0;
                        }
                        break;
                    case 5:
                        availableSensors = [1, 1, 1, 1];

                        if (parameters.p_84.value == 1) {
                            availableSensors[parameters.p_86.value] = 0;
                        } else if (parameters.p_84.value == 2) {
                            availableSensors[parameters.p_86.value] = 0;
                            availableSensors[parameters.p_179.value] = 0;
                        }
                        break;
                    case 6:
                        availableSensors = [0, 0, 1, 1];
                        break;
                    case 7:
                        availableSensors = [1, 1, 1, 1];

                        if (parameters.p_78.value == 1) {
                            availableSensors[parameters.p_80.value] = 0;
                        } else if (parameters.p_78.value == 2) {
                            availableSensors[parameters.p_80.value] = 0;
                            availableSensors[parameters.p_177.value] = 0;
                        }

                        if (parameters.p_81.value == 1) {
                            availableSensors[parameters.p_83.value] = 0;
                        } else if (parameters.p_81.value == 2) {
                            availableSensors[parameters.p_83.value] = 0;
                            availableSensors[parameters.p_178.value] = 0;
                        }

                        if (parameters.p_84.value == 1) {
                            availableSensors[parameters.p_86.value] = 0;
                        } else if (parameters.p_84.value == 2) {
                            availableSensors[parameters.p_86.value] = 0;
                            availableSensors[parameters.p_179.value] = 0;
                        }
                        break;
                    case 8:
                        availableSensors = [0, 0, 1, 1];
                        if (parameters.p_81.value == 1) {
                            availableSensors[parameters.p_83.value] = 0; //when module is thermostat or recycle this sensor is being used
                        } else if (parameters.p_81.value == 2) {
                            availableSensors[parameters.p_83.value] = 0;
                            availableSensors[parameters.p_178.value] = 0; //when module is recycle this additional sensor is being used
                        }

                        if (parameters.p_84.value == 1) {
                            availableSensors[parameters.p_86.value] = 0;
                        } else if (parameters.p_84.value == 2) {
                            availableSensors[parameters.p_86.value] = 0;
                            availableSensors[parameters.p_179.value] = 0;
                        }
                        break;
                    case 9:
                        availableSensors = [0, 0, 0, 1];
                        if (parameters.p_84.value == 1) {
                            availableSensors[parameters.p_86.value] = 0;
                        } else if (parameters.p_84.value == 2) {
                            availableSensors[parameters.p_86.value] = 0;
                            availableSensors[parameters.p_179.value] = 0;
                        }
                        break;
                    case 10:
                        availableSensors = [0, 0, 0, 1];

                        if (parameters.p_84.value == 1) {
                            availableSensors[parameters.p_86.value] = 0;
                        } else if (parameters.p_84.value == 2) {
                            availableSensors[parameters.p_86.value] = 0;
                            availableSensors[parameters.p_179.value] = 0;
                        }
                        break;
                }

                return availableSensors
            }
        },

        watch: {
            getAvailableSensors: function (value) {
                if (value[0] == 0 && value[1] == 0 && value[2] == 0 && value[3] == 0) {
                    this.collapseOpen = false; //in case collapse is open when v-if is true when it shows again it is buggy
                }
            }
        }
    }
</script>

<style scoped>
    .collapse-btn-style {
        padding: 0rem 0.4rem;
        margin: 0px 10px 0px 10px;
        min-width: 1.5rem; /*Needed because button gets narrower when icon is changed from '+' to '-'*/
    }

    .card-body {
        padding: 0;
    }
</style>