<template>
    <div v-if="shouldShow()" class="py-2">
        <strong>{{ moduleName }}</strong>
        <b-btn v-b-toggle="collapseId" variant="primary" class="collapse-btn-style" @pointerdown="collapseOpen = !collapseOpen">{{ collapseIcon }}</b-btn>
        <b-collapse :id="collapseId" class="mt-2" v-on:show="initComponents">
            <b-card style="border: none;">
                <hr style="margin-top: 0.4rem;">
                <div v-for="(value, key) in parameters" :key="'div_' + key" v-if='shouldRenderParameter(key)'>
                    <div v-if="shouldRenderSettingsHeader(key)" class="py-2">
                        <strong>Settings</strong>
                        <hr>
                    </div>

                    <Parameter :param-key="key" :param-value="value" :key="key"></Parameter>
                </div>

                <div v-if="isModuleActive && isOpModeSchedule" class="py-3">
                    <b-row>
                        <b-col>
                            <strong class="float-left mr-2">Schedule</strong>
                            <spinner v-if="showScheduleSpinner" size="20" class="float-left"></spinner>
                            <schedule-warning-label v-if="showWarning" label-id="0" :show-warning="showWarning"></schedule-warning-label>
                        </b-col>
                    </b-row>
                    <hr>

                    <mobile-schedule v-if="shouldRenderMobileSchedule" :module-number="moduleNumber" :key="'mk_' + moduleNumber"></mobile-schedule>
                    <desktop-schedule v-else :module-number="moduleNumber" :key="'dk_' + moduleNumber"></desktop-schedule>
                </div>
            </b-card>
        </b-collapse>
    </div>
</template>

<script>
    import {mapGetters} from 'vuex';
    import Parameter from "../parameter/ParameterV2";
    import Spinner from 'vue-simple-spinner';
    import DesktopSchedule from "../schedules/DesktopSchedule";
    import MobileSchedule from "../schedules/MobileSchedule";
    import ScheduleWarningLabel from "../schedules/ScheduleWarningLabel";

    export default {
        name: "Module",

        components: {
            MobileSchedule,
            Parameter,
            DesktopSchedule,
            Spinner,
            ScheduleWarningLabel
        },

        props: [
            "moduleNumber",
            "moduleName",
            "modulesLookup"
        ],

        data() {
            return {
                collapseOpen: false,
                showScheduleSpinner: false,
                showWarning: false,
                windowWidth: 0,
                windowWidthFlag: true //don't emit event the first time that windowWidth is set
            }
        },

        watch: {
            windowWidth: function (newWidth, oldWidth) {
                if (!this.windowWidthFlag && oldWidth <= 976 && newWidth > 976) { //execute only when passing the 976 breakpoint for the first time
                    this.$nextTick(() => {
                        this.$root.$emit("mount-timeline", this.moduleNumber);
                    })
                } else {
                    this.windowWidthFlag = false;
                }
            }
        },

        computed: {
            ...mapGetters({
                getParameters: 'virtualDevice/parameters'
            }),

            parameters: function () {
                return this.getParameters(this.$route.params.id);
            },

            shouldRenderMobileSchedule: function () {

                return this.windowWidth <= 976;
            },

            collapseId: function () {
                return this.moduleName.replace(/ /g, '') + "_collapse"; //collapse id does not support whitespaces anymore
            },

            collapseIcon: function () {
                return this.collapseOpen === false ? '+' : '-';
            },

            isModuleActive: function () {
                if (this.moduleName === 'Module 1') return parseInt(this.parameters.p_78.value) !== 0;
                else if (this.moduleName === 'Module 2') return parseInt(this.parameters.p_81.value) !== 0;
                else if (this.moduleName === 'Module 3') return parseInt(this.parameters.p_84.value) !== 0;
            },

            isOpModeSchedule: function () {
                if (this.moduleName === 'Module 1' && this.parameters.p_78) {
                    if (parseInt(this.parameters.p_78.value) === 1 && this.parameters.p_101) return parseInt(this.parameters.p_101.value) === 2;
                    else if (parseInt(this.parameters.p_78.value) === 2 && this.parameters.p_103) return parseInt(this.parameters.p_103.value) === 2;
                    else if (parseInt(this.parameters.p_78.value) === 3 && this.parameters.p_104) return parseInt(this.parameters.p_104.value) === 2;
                } else if (this.moduleName === 'Module 2' && this.parameters.p_81) {
                    if (parseInt(this.parameters.p_81.value) === 1 && this.parameters.p_105) return parseInt(this.parameters.p_105.value) === 2;
                    else if (parseInt(this.parameters.p_81.value) === 2 && this.parameters.p_107) return parseInt(this.parameters.p_107.value) === 2;
                    else if (parseInt(this.parameters.p_81.value) === 3 && this.parameters.p_108) return parseInt(this.parameters.p_108.value) === 2;
                } else if (this.moduleName === 'Module 3' && this.parameters.p_84) {
                    if (parseInt(this.parameters.p_84.value) === 1 && this.parameters.p_109) return parseInt(this.parameters.p_109.value) === 2;
                    else if (parseInt(this.parameters.p_84.value) === 2 && this.parameters.p_111) return parseInt(this.parameters.p_111.value) === 2;
                    else if (parseInt(this.parameters.p_84.value) === 3 && this.parameters.p_112) return parseInt(this.parameters.p_112.value) === 2;
                }
            }
        },

        created() {
            this.$root.$on('show-schedule-spinner', (value) => {
                this.showScheduleSpinner = value;
            });

            this.$root.$on('show-warning-label', (value) => {
                this.showWarning = value;
            });

            window.addEventListener('resize', this.handleResize);

            this.windowWidth = window.innerWidth;
        },

        destroyed() {
            this.$root.$off('show-schedule-spinner');
            this.$root.$off('show-warning-label');
            window.removeEventListener('resize', this.handleResize);
        },

        methods: {
            initComponents() {
                this.$root.$emit("refresh-slider");
                this.$root.$emit("mount-timeline", this.moduleNumber);
            },

            handleResize() {
                this.windowWidth = window.innerWidth;
            },

            shouldShow() {
                let parameters = Object.keys(this.parameters);

                return parameters.some(r => this.modulesLookup[this.moduleName].includes(r));
            },

            shouldRenderSettingsHeader(key) {
                return key === 'p_101' || key === 'p_105' || key === 'p_109' || key === 'p_103' || key === 'p_107' || key === 'p_111' || key === 'p_104'
                    || key === 'p_108' || key === 'p_112';
            },

            shouldRenderParameter(key) {
                if (this.modulesLookup[this.moduleName].indexOf(key) > -1) {
                    if (key === 'p_80') return parseInt(this.parameters.p_78.value) !== 3; //Don't display sensor during ON/OFF mode
                    else if (key === 'p_83') return parseInt(this.parameters.p_81.value) !== 3;
                    else if (key === 'p_86') return parseInt(this.parameters.p_84.value) !== 3;
                    else if (key === 'p_35' || key === 'p_36' || key === 'p_37' || key === 'p_102') {
                        return parseInt(this.parameters.p_101.value) !== 0; //Don't display logic if operation not active
                    } else if (key === 'p_87' || key === 'p_88' || key === 'p_89' || key === 'p_106') {
                        return parseInt(this.parameters.p_105.value) !== 0;
                    } else if (key === 'p_94' || key === 'p_95' || key === 'p_96' || key === 'p_110') {
                        return parseInt(this.parameters.p_109.value) !== 0;
                    } else if (key === 'p_39' || key === 'p_40' || key === 'p_63') {
                        return parseInt(this.parameters.p_103.value) !== 0;
                    } else if (key === 'p_91' || key === 'p_92' || key === 'p_93') {
                        return parseInt(this.parameters.p_107.value) !== 0;
                    } else if (key === 'p_98' || key === 'p_99' || key === 'p_100') {
                        return parseInt(this.parameters.p_111.value) !== 0;
                    } else return true
                } else return false;
            }
        }
    }
</script>

<style scoped>
    .collapse-btn-style {
        padding: 0rem 0.4rem;
        margin: 0px 10px 0px 10px;
        min-width: 1.5rem; /*Needed because button gets narrower when icon is changed from '+' to '-'*/
    }

    .card-body {
        padding: 0;
    }
</style>
