<template>
    <b-row class="pb-2">
        <parameter-tooltip :parameter-key="paramKey" :parameter-description="paramValue.description"></parameter-tooltip>

        <b-col id="info" style="padding-top: 0.2rem;" class="py-2 py-sm-1" cols="7" sm="3" md="3" lg="3" xl="3">
            <label>{{ paramValue.name }}</label>
        </b-col>

        <b-col class="py-2 py-sm-1" cols="1" sm="2" md="2" lg="2" xl="1" v-if="isReadOnly(paramKey)">
            <label :id="labelIdFormat(paramKey)" style="text-align: right"><strong>{{ this.labelTextFormatter(paramKey, paramValue.value)
                }}</strong></label>
        </b-col>

        <b-col class="py-2 py-sm-1" cols="10" sm="7" md="7" lg="7" xl="5">
            <div v-if="paramValue.type === 'boolean'">
                <b-form-radio-group :id="inputIdFormat(paramKey)" :name="paramKey" v-model="value">
                    <b-form-radio value="1">On</b-form-radio>
                    <b-form-radio value="0">Off</b-form-radio>
                </b-form-radio-group>
            </div>

            <div v-if="paramValue.type === 'slider'">
                <div v-if="paramKey === 'p_1'">
                    <vue-slider class="px-0 pb-4" v-model="value" :dot-size="16" :height="6" :min="paramValue.min" :max="paramValue.max" :lazy="false"
                                ref="slider" :disabled="pending !== false" :marks="false" :process-style="sliderProcessStyle"
                                :tooltip-style="sliderProcessStyle"
                                :tooltip="isMobileView ? 'focus' : 'none'" @drag-start="dragStart()" @change="variantValueChange()"
                                @drag-end="dragEnd()">

                        <template v-slot:dot="{ value, focus }">
                            <div :class="[(draggingVariantSlider && isMobileView) ? 'custom-dot-dragged' : 'custom-dot', { focus }]">
                                <span class="custom-dot-text">
                                    {{(draggingVariantSlider && isMobileView) ? '' : getLabelFormattedValue(value)}}
                                </span>
                            </div>
                        </template>
                    </vue-slider>

                    <b-carousel no-animation id="variant-carousel" class="slide" background="#ffffff" :interval="0" img-width="370"
                                img-height="186" v-model="variantSlide">

                        <div v-if="uiVersion === 'tdc_v2' || uiVersion === 'tdc_v3' || uiVersion === 'pln_v1' || uiVersion === 'pln_v1_5'">
                            <b-carousel-slide style="outline: none;" img-src="../img/variants/v1/variant1.jpg"></b-carousel-slide>
                            <b-carousel-slide style="outline: none;" img-src="../img/variants/v1/variant2.jpg"></b-carousel-slide>
                            <b-carousel-slide style="outline: none;" img-src="../img/variants/v1/variant3.jpg"></b-carousel-slide>
                            <b-carousel-slide style="outline: none;" img-src="../img/variants/v1/variant4.jpg"></b-carousel-slide>
                            <b-carousel-slide style="outline: none;" img-src="../img/variants/v1/variant5.jpg"></b-carousel-slide>
                            <b-carousel-slide style="outline: none;" img-src="../img/variants/v1/variant6.jpg"></b-carousel-slide>
                            <b-carousel-slide style="outline: none;" img-src="../img/variants/v1/variant7.jpg"></b-carousel-slide>
                        </div>

                    </b-carousel>

                    <b-modal id="modal" centered header-bg-variant="info" ref="variantModal" title="Change variant" @ok="variantUpdate()"
                             @cancel="variantUpdateCancel()" no-close-on-backdrop no-close-on-esc hide-header-close>
                        <span>
                            Are you sure that you want to change the device variant? <br>
                            Doing so can make you lose some of the configurations you have set on the device.
                        </span>
                    </b-modal>
                </div>

                <vue-slider v-else v-model="value" class="px-0" :dot-size="16" :height="6" :min="paramValue.min" :max="paramValue.max" :lazy="true"
                            ref="slider" :tooltip="isMobileView ? 'focus' : 'none'" :marks="false" :interval="1"
                            :tooltip-formatter="getLabelFormattedValue" :process-style="sliderProcessStyle" :tooltip-style="sliderProcessStyle"
                            @drag-start="draggingSlider = true" @drag-end="draggingSlider = false">

                    <template v-slot:dot="{ value, focus }">
                        <div :class="[(draggingSlider && isMobileView) ? 'custom-dot-dragged' : 'custom-dot', { focus }]">
                            <span class="custom-dot-text">
                                {{(draggingSlider && isMobileView) ? '' : getLabelFormattedValue(value)}}
                            </span>
                        </div>
                    </template>
                </vue-slider>
            </div>

            <div v-if="paramValue.type === 'setpoint'">
                <vue-slider v-if="shouldRenderSetpointSlider()" class="px-0" v-model="value" :min="paramValue.min" :max="paramValue.max" :lazy="true"
                            ref="slider" :dot-size="16" :height="6" :tooltip="isMobileView ? 'focus' : 'none'" :marks="false" :interval="1"
                            :tooltip-formatter="getLabelFormattedValue" :process-style="sliderProcessStyle" :tooltip-style="sliderProcessStyle"
                            @drag-start="draggingSlider = true" @drag-end="draggingSlider = false">

                    <template v-slot:dot="{ value, focus }">
                        <div :class="[(draggingSlider && isMobileView) ? 'custom-dot-dragged' : 'custom-dot', { focus }]">
                            <span class="custom-dot-text">
                                {{(draggingSlider && isMobileView) ? '' : getLabelFormattedValue(value)}}
                            </span>
                        </div>
                    </template>
                </vue-slider>
                <strong v-else>{{ setpointValue }}</strong>
            </div>

            <div v-if="paramValue.type === 'offDailyWeekly'">
                <b-form-radio-group :id="inputIdFormat(paramKey)" :name="paramKey" v-model="value">
                    <b-form-radio value="0">Off</b-form-radio>
                    <b-form-radio value="1">Daily</b-form-radio>
                    <b-form-radio value="2">Weekly</b-form-radio>
                </b-form-radio-group>
            </div>

            <div v-if="paramValue.type === 'offOnSchedule'">
                <b-form-radio-group :id="inputIdFormat(paramKey)" :name="paramKey" v-model="value">
                    <b-form-radio value="0">Off</b-form-radio>
                    <b-form-radio value="1">On</b-form-radio>
                    <b-form-radio value="2">Schedule</b-form-radio>
                </b-form-radio-group>
            </div>

            <div v-if="paramValue.type === 'moduleType'">
                <b-form-radio-group :id="inputIdFormat(paramKey)" :name="paramKey" v-model="value">
                    <b-form-radio value="0">Disabled</b-form-radio>
                    <b-form-radio value="1">Thermostat</b-form-radio>
                    <b-form-radio value="2">Recycle</b-form-radio>
                    <b-form-radio value="3">On/Off</b-form-radio>
                </b-form-radio-group>
            </div>

            <div v-if="paramValue.type === 'storageSensor'">
                <b-form-radio-group :id="inputIdFormat(paramKey)" :name="paramKey" v-model="value">
                    <b-form-radio value="0">S1</b-form-radio>
                    <b-form-radio value="1">S2</b-form-radio>
                    <b-form-radio value="2">S3</b-form-radio>
                    <b-form-radio value="3">S4</b-form-radio>
                </b-form-radio-group>
            </div>

            <!--Readonly-->
            <div v-if="paramValue.type === 'moduleSensor'"></div>
            <!--Readonly-->
            <div v-if="paramValue.type === 'moduleRelay'"></div>

            <div v-if="paramValue.type === 'mode'">
                <b-form-radio-group :id="inputIdFormat(paramKey)" :name="paramKey" v-model="value">
                    <b-form-radio value="0">Heat</b-form-radio>
                    <b-form-radio value="1">Cool</b-form-radio>
                </b-form-radio-group>
            </div>

            <div v-if="paramValue.type === 'lowHigh'">
                <b-form-radio-group :id="inputIdFormat(paramKey)" :name="paramKey" v-model="value">
                    <b-form-radio value="1">Low</b-form-radio>
                    <b-form-radio value="2">High</b-form-radio>
                </b-form-radio-group>
            </div>

            <div v-else-if="!paramValue.type">
                <input type="number" autocomplete="off" class="form-control input-sm"
                       :id="inputIdFormat(paramKey)" :name="paramKey" v-model="value">
            </div>
        </b-col>

        <b-col class="py-2 py-sm-1" cols="1" sm="1" md="1" lg="1" xl="1" :id="pendingIdFormat(paramKey)" v-show="pending || showWarning">
            <spinner v-if="!showWarning" size="22" class="float-left" line-fg-color="var(--primary)"></spinner>

            <div id="warning" v-else>
                <b-btn :id="'warning_' + paramKey" class="device-button" style="color: white; background-color: white;
                 border-color: white; padding: 0; margin: 0;">
                    <i style="color: orange; font-size: 22px; padding: 0;" class="fa fa-warning"></i>
                </b-btn>
                <b-tooltip :target="'warning_' + paramKey" title="Communication error, check if device is online." placement="left"
                           triggers="click"></b-tooltip>
            </div>
        </b-col>
    </b-row>
</template>

<script>
    import {mapGetters} from 'vuex';
    import vueSlider from 'vue-slider-component';
    import 'vue-slider-component/theme/default.css';
    import {isMobile} from "@/utilities/mobile.navigation.utility";
    import {getSliderDotValue} from "@/utilities/parameter.utility";
    import Spinner from 'vue-simple-spinner';
    import {valueFormatter, isReadOnly, getSetpointValue} from "@/utilities/parameter.utility";
    import ParameterTooltip from "./v3/edit/ParameterTooltip";

    export default {
        name: "Parameter",

        components: {
            ParameterTooltip,
            Spinner,
            vueSlider
        },

        computed: {
            ...mapGetters({
                getParameters: 'virtualDevice/parameters',
                getUiVersion: 'virtualDevice/uiVersion'
            }),

            parameters: function () {
                return this.getParameters(this.$route.params.id);
            },

            uiVersion: function () {
                return this.getUiVersion(this.$route.params.id);
            },

            isMobileView() {
                return isMobile();
            },

            variantSlide: {
                get: function () {
                    return this.value - 1
                },

                set: function (newValue) {
                    //added to bypass vue warning
                }
            },

            setpointValue() {
                return getSetpointValue(this.paramKey, this.parameters);
            }
        },

        props: [
            'paramValue',
            'paramKey'
        ],

        data() {
            return {
                value: this.paramValue.value.toString(), //radio buttons need values to be string
                pending: false,
                pendingInterval: null,
                physicalDeviceUpdate: false,
                retryInterval: null,
                numberOfRetries: 0,
                showWarning: false,
                draggingVariantSlider: false,
                draggingSlider: false,
                sliderProcessStyle: {backgroundColor: 'var(--primary)'},
                beforeVariantDragValue: this.paramValue.value.toString(),
                marksFormatter: val => this.shouldRenderMark(val) ? ({
                    label: this.shouldRenderDegreeSign() ? `${val}℃` : val,
                    labelStyle: {
                        display: 'block',
                        // backgroundColor: '#69c0ff',
                        top: '5px',
                        color: '#676768',
                        transform: this.getLabelTransform(val)
                    }
                }) : false
            }
        },

        watch: {
            value: function () {
                if (!this.physicalDeviceUpdate && !this.draggingVariantSlider) {
                    this.updateParameter();
                } else {
                    this.physicalDeviceUpdate = false;
                }
            }
        },

        created() {
            this.$watch('parameters.' + this.paramKey + '.value', function () { //watch changes transmitted from physical device
                if (!this.pending && parseInt(this.value) !== parseInt(this.parameters[this.paramKey].value)) {
                    this.value = this.parameters[this.paramKey].value;
                    this.physicalDeviceUpdate = true;
                }
            });
        },

        beforeDestroy() {
            clearInterval(this.pendingInterval);
            clearInterval(this.retryInterval);
        },

        methods: {
            isReadOnly(key) {
                return isReadOnly(key);
            },

            getLabelTransform(val) {
                if (val === this.paramValue.min) {
                    return 'translateX(0px)';
                } else {
                    if (this.shouldRenderDegreeSign(val)) {
                        if (parseInt(this.paramValue.max) < 10) {
                            return 'translateX(-22px)';
                        } else if (parseInt(this.paramValue.max) < 100) {
                            return 'translateX(-30px)';
                        } else {
                            return 'translateX(-40px)';
                        }
                    } else {
                        if (parseInt(this.paramValue.max) < 10) {
                            return 'translateX(-7px)';
                        } else if (parseInt(this.paramValue.max) < 100) {
                            return 'translateX(-15px)';
                        } else {
                            return 'translateX(-22px)';
                        }
                    }
                }
            },

            shouldRenderMark(val) {
                return val == this.paramValue.min || val == this.paramValue.max;

            },

            shouldRenderDegreeSign() {
                return this.paramKey != 'p_1' && this.paramKey != 'p_33' && this.paramKey != 'p_71';
            },

            variantUpdate() {
                this.draggingVariantSlider = false;
                this.updateParameter();
            },

            variantUpdateCancel() {
                this.draggingVariantSlider = false;
                this.physicalDeviceUpdate = true; //because we do not want the value that is being set below to trigger a parameter update
                this.value = this.beforeVariantDragValue;
            },

            dragStart() {
                this.draggingVariantSlider = true;
                this.beforeVariantDragValue = this.value;
                this.showWarning = false;
                if (this.pending) {
                    this.pending = false;
                    clearInterval(this.pendingInterval);
                    clearInterval(this.retryInterval);
                }
            },

            dragEnd() {
                if (this.beforeVariantDragValue.toString() !== this.value.toString()) {
                    this.$refs.variantModal.show();
                }
            },

            variantValueChange() {
                if (!this.draggingVariantSlider) {
                    this.draggingVariantSlider = true;
                    this.showWarning = false;
                    if (this.pending) {
                        this.pending = false;
                        clearInterval(this.pendingInterval);
                        clearInterval(this.retryInterval);
                    }
                    this.$refs.variantModal.show();
                }
            },

            updateParameter() {
                let parameter = {};
                parameter['id'] = this.$route.params.id;
                parameter[this.paramKey] = this.value;

                this.$axios.post('/update-parameter', {
                    data: parameter
                });

                this.showWarning = false;
                this.pending = true;
                if (this.pendingInterval !== null) {
                    clearInterval(this.pendingInterval);
                    clearInterval(this.retryInterval);
                }

                this.pendingInterval = setInterval(() => {
                    if (parseInt(this.parameters[this.paramKey].value) === parseInt(this.value)) {
                        this.pending = false;
                        clearInterval(this.pendingInterval);
                        clearInterval(this.retryInterval);
                    }
                }, 100);

                this.retryInterval = setInterval(() => {
                    this.numberOfRetries += 1;
                    if (this.numberOfRetries <= 5) {
                        this.$axios.post('/update-parameter', {
                            data: parameter
                        });
                    } else {
                        this.numberOfRetries = 0;
                        this.showWarning = true;
                        this.pending = false;
                        clearInterval(this.pendingInterval);
                        clearInterval(this.retryInterval);
                        this.physicalDeviceUpdate = true; //because we do not want to trigger the value watcher when changing the value below
                        this.value = this.parameters[this.paramKey].value.toString();
                    }
                }, 5000, parameter)
            },

            shouldRenderSetpointSlider() {
                if (this.paramKey === 'p_35') return parseInt(this.parameters.p_101.value) !== 2;
                else if (this.paramKey === 'p_87') return parseInt(this.parameters.p_105.value) !== 2;
                else if (this.paramKey === 'p_94') return parseInt(this.parameters.p_109.value) !== 2;
                else if (this.paramKey === 'p_39') return parseInt(this.parameters.p_103.value) !== 2;
                else if (this.paramKey === 'p_91') return parseInt(this.parameters.p_107.value) !== 2;
                else if (this.paramKey === 'p_98') return parseInt(this.parameters.p_111.value) !== 2;
            },

            pendingIdFormat(id) {
                return "pending_" + id;
            },

            labelIdFormat(id) {
                return "label_" + id;
            },

            inputIdFormat(id) {
                return "input_" + id;
            },

            labelTextFormatter(key, value) {
                return valueFormatter(key, value);
            },

            getLabelFormattedValue(val) {
                return getSliderDotValue(this.parameterKey, val, this.paramValue.min, this.paramValue.max, this.parameters);
            }
        }
    }
</script>

<style scoped>
    #warning .btn:focus, .btn:active {
        outline: none;
        box-shadow: none;
    }

    #info .btn:focus, .btn:active {
        outline: none;
        box-shadow: none;
    }
</style>















